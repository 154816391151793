import React from 'react'
import styled from 'styled-components'
import SecondaryNav from '../SubNavigation'

export const ContentWrapper = styled.div`
  font-size: 12px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: ${props => props.theme.appBackground};
`
ContentWrapper.displayName = 'ContentWrapper'

const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
`
CustomContainer.displayName = 'CustomContainer'

export default ({ children, Header, Footer }) => (
  <ContentWrapper>
    <Header />
    <SecondaryNav />
    <CustomContainer>
      {children}
    </CustomContainer>
    <Footer />
  </ContentWrapper>
)
