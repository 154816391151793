import React from 'react'
import styled from 'styled-components'
import { Link as RRLink } from 'react-router-dom'
import { setDisplayName } from 'recompose'
import {
  Theme as PgaTheme
} from '@pga/pga-component-library'
import { formatPhone, formatPhoneHref } from '../functions/formatters'
const CleanAnchor = styled.a`
  color: initial;
  text-decoration: initial;
  font-weight: ${props => props.strong ? '500' : 'normal'};
  :hover {
    text-decoration: initial;    
  }
`

export default setDisplayName('Link')(({ to, children, ...rest }) => (
  <CleanAnchor href={to} {...rest}>{children}</CleanAnchor>
))

const linkStyles = `
  color: ${PgaTheme.colors.lightBlue};
  font-family: ${PgaTheme.fonts.Montserrat};
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  line-height: 2;
`

export const Link = styled.a`
  ${linkStyles}
`

export const RouterLink = styled(RRLink).attrs(props => ({
  rel: (props.target && props.target.contain('blank')) && 'noopener noreferrer'
}))`
  ${linkStyles}
`

export const Tel = ({ phone }) => <Link href={`tel:${formatPhoneHref(phone)}`}>{formatPhone(phone)}</Link>

export const Mailto = ({ address }) => <Link href={`mailto:${address}`}>{address}</Link>
