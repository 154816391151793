import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { withAuthProvider } from '../../../lib/auth'

export const AuthRedirect = ({ component: Component, redirectTo, ...props }) => {
  const { isLoggedIn, authReady } = props

  if (authReady && (!isLoggedIn)) {
    return (
      <Redirect
        from={props.location.pathname}
        to={{
          pathname: redirectTo || '/',
          state: {
            authRedirect: true,
            from: props.location.pathname
          }
        }}
      />
    )
  }
  return (
    <Route
      {...props}
      render={renderProps => (
        <Component {...props} {...renderProps} />
      )}
    />
  )
}

export default withAuthProvider(AuthRedirect)
