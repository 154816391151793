import 'react-app-polyfill/ie11' // For IE 11 support
import 'bootstrap/dist/css/bootstrap.css'
import 'instantsearch.css/themes/algolia.css'
import './index.css'
import './assets/icons/font-icons.css'
import 'font-awesome/css/font-awesome.css'

import React from 'react'
import ReactDOM from 'react-dom'
import './polyfills'
import App from './features/app/App'
import { unregister } from './registerServiceWorker'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import apolloClient from './features/apolloClient'
import { BlockAppAccess, BLOCK_APP_ACCESS } from './features/app/BlockAppAccess'

const AppWrapper = () => (
  <ApolloProvider client={apolloClient}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
    </BrowserRouter>
  </ApolloProvider>
)

const RenderView = () => BLOCK_APP_ACCESS ? <BlockAppAccess /> : <AppWrapper />

ReactDOM.render(RenderView(), document.getElementById('root'))

unregister()
