import { PrivateRoute, RedirectToLogin } from '../../lib/auth'
import { LoadingComponent, withPageMetadata, metadata } from '@pga/pga-component-library'
import React from 'react'
import Loadable from 'react-loadable'
import { Route, Switch } from 'react-router-dom'
import { path } from 'ramda'
import AuthRedirect from './modal/AuthRedirect'

const AsyncJobDashboard = Loadable({ loader: () => import('../job-search/Dashboard'), loading: LoadingComponent })
const AsyncJobSearch = Loadable({ loader: () => import('../job-search'), loading: LoadingComponent })
const RecommendedJobsPage = Loadable({ loader: () => import('../job-search/RecommendedJobsPage'), loading: LoadingComponent })
const AsyncJobDetailPage = Loadable({ loader: () => import('../job-posting/JobDetailPage'), loading: LoadingComponent })
const AsyncApplicationSubmittedPage = Loadable({
  loader: () => import('../job-posting/ApplicationSubmittedPage'),
  loading: LoadingComponent
})
const AsyncLogout = Loadable({ loader: () => import('../Logout'), loading: LoadingComponent })
const AsyncEditJob = Loadable({ loader: () => import('../EditJob'), loading: LoadingComponent })
const AsyncManageJobs = Loadable({ loader: () => import('../ManageJobs'), loading: LoadingComponent })
const AsyncPostJob = Loadable({ loader: () => import('../PostJob'), loading: LoadingComponent })
const NotFound = Loadable({ loader: () => import('../NotFound'), loading: LoadingComponent })
const AsyncCopyJob = Loadable({ loader: () => import('../CopyJob'), loading: LoadingComponent })
const AsyncCaseClose = Loadable({ loader: () => import('../CaseClose'), loading: LoadingComponent })

const AsyncComingSoon = Loadable({
  loader: () => import('@pga/pga-component-library/lib/components/ComingSoon'),
  loading: LoadingComponent
})

const renderJobSearch = withPageMetadata(metadata('Job Board'))(AsyncJobSearch)
const renderJobDashboard = withPageMetadata(metadata('Job Dashboard'))(AsyncJobDashboard)

export const privateRoutes = [
  {
    path: '/manage-jobs',
    redirectTo: '/',
    component: withPageMetadata(metadata('Manage Job'))(AsyncManageJobs)
  },
  {
    path: '/post',
    redirectTo: '/',
    component: withPageMetadata(metadata('Post A Job'))(AsyncPostJob)
  },
  {
    path: '/copy/:id',
    redirectTo: '/',
    component: withPageMetadata(metadata('Copy a Job'))(AsyncCopyJob)
  },
  {
    path: '/new-hire-response/:id',
    redirectTo: '/',
    component: withPageMetadata(metadata('Case Close Response'))(AsyncCaseClose)
  },
  {
    path: '/recommended',
    redirectTo: '/',
    component: withPageMetadata(metadata('Recommended Jobs'))(RecommendedJobsPage)
  }
]

export default () => (
  <Switch>
    <Route exact path='/' component={renderJobDashboard} />
    <Route exact path='/search' component={renderJobSearch} />
    <Route
      exact
      path='/login'
      component={props => {
        const page = path(['location', 'state', 'page'], props)
        return <RedirectToLogin returnTo={page ? window.location.origin + page : `${window.location.origin}/manage-jobs`} />
      }}
    />
    <Route path='/board' component={withPageMetadata(metadata('Job Board'))(AsyncJobSearch)} />
    <Route path='/detail/:id' component={withPageMetadata(metadata('Job Details'))(AsyncJobDetailPage)} />
    <Route path='/application/thank-you' component={AsyncApplicationSubmittedPage} />
    <Route exact path='/logout' component={AsyncLogout} />
    {
      privateRoutes.map(routeProps => <AuthRedirect key={routeProps.path} {...routeProps} />)
    }
    <PrivateRoute path='/edit/:id' component={withPageMetadata(metadata('Edit Job'))(AsyncEditJob)} />
    <Route exact path='/coming-soon' component={AsyncComingSoon} />
    <Route component={withPageMetadata(metadata('NOT FOUND'))(NotFound)} />
  </Switch>
)
