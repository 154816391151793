import React from 'react'
import { ThemeProvider } from 'styled-components'
import { AuthProvider, ImpersonationOverlay } from '../../lib/auth'
import Routes, { privateRoutes } from './Routes'
import theme from '../theme'
import Layout from './Layout'
import Header from '../Header'
import Footer from '../Footer'
import RecommendedJobsProvider from '../../features/job-search/RecommendedJobsProvider'
import PGALogo from '../../assets/images/logo.svg'
import IntercomWithAuth from '../Intercom'
import RaygunUser from '../Raygun'
import PromptModal from './modal/PromptModal'

export const GetHeader = () => <Header {...{ logo: PGALogo, bgColor: theme.colors.secondary }} />
export const GetFooter = () => <Footer />

export default () => (
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <RecommendedJobsProvider>
        <ImpersonationOverlay />
        <Layout Header={GetHeader} Footer={GetFooter}>
          <PromptModal privateRoutes={privateRoutes} />
          <Routes />
        </Layout>
        <RaygunUser />
      </RecommendedJobsProvider>
      <IntercomWithAuth />
    </AuthProvider>
  </ThemeProvider>
)
