import React from 'react'

let AuthProvider,
  PrivateRoute,
  RedirectToLogin,
  RedirectToLogout,
  withAuthProvider,
  getToken,
  setToken

const { getImpersonationHeader, ImpersonationOverlay } = require('@pga/auth-flow-provider').impersonation

if (process.env.REACT_APP_AUTH_IMPL === 'auth0') {
  const AuthFlowProvider = require('@pga/auth-flow-provider').auth0.AuthFlowProvider
  const getMeUser = require('../queries').getMeUser
  const print = require('graphql').print
  const fetchCurrentUser = async (token) => {
    try {
      const res = await fetch(process.env.REACT_APP_V3_API_HOST, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          ...getImpersonationHeader()
        },
        body: JSON.stringify({ query: print(getMeUser) })
      })

      const json = await res.json()
      const currentUser = json && json.data && json.data.me
      if (!currentUser) {
        throw new Error('No user found')
      }
      return [currentUser, null]
    } catch (err) {
      return [null, err]
    }
  }
  const { LoadingComponent } = require('@pga/pga-component-library')
  const renderRedirecting = () => (<LoadingComponent />)
  AuthProvider = ({ children }) => (
    <AuthFlowProvider fetchCurrentUser={fetchCurrentUser} renderRedirecting={renderRedirecting}>
      {children}
    </AuthFlowProvider>
  )

  PrivateRoute = require('@pga/auth-flow-provider').auth0.PrivateRoute
  RedirectToLogin = require('@pga/auth-flow-provider').auth0.RedirectToLogin
  RedirectToLogout = require('@pga/auth-flow-provider').auth0.RedirectToLogout
  withAuthProvider = require('@pga/auth-flow-provider').auth0.withAuthProvider
  getToken = require('@pga/auth-flow-provider').auth0.getToken
  setToken = (_) => {} // no-op
} else {
  const AuthFlowProvider = require('@pga/auth-flow-provider').onelogin.AuthFlowProvider
  const apolloClient = require('../features/apolloClient').default
  const getMeUser = require('../queries').getMeUser
  AuthProvider = ({ children }) => (
    <AuthFlowProvider apolloClient={apolloClient} sessionCheckInterval={60000} query={getMeUser}>
      {children}
    </AuthFlowProvider>
  )

  PrivateRoute = require('@pga/auth-flow-provider').onelogin.PrivateRoute
  RedirectToLogin = require('@pga/auth-flow-provider').onelogin.RedirectToLogin
  RedirectToLogout = require('@pga/auth-flow-provider').onelogin.RedirectToLogout
  withAuthProvider = require('@pga/auth-flow-provider').onelogin.withAuthProvider
  getToken = require('@pga/auth-flow-provider').onelogin.getToken
  setToken = require('@pga/auth-flow-provider').onelogin.setToken
}

export {
  AuthProvider,
  PrivateRoute,
  RedirectToLogin,
  RedirectToLogout,
  withAuthProvider,
  getToken,
  setToken,
  getImpersonationHeader,
  ImpersonationOverlay
}
