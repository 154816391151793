export default {
  appBackground: 'rgb(238, 240, 241)',
  searchFormText: '#999999',
  searchFilterPlaceholderText: '#999999',
  searchResultItem: '#6c757d',
  searchResultItemIcon: '#ab9157',
  modalContentBackground: '#EFEFEF',
  modalHeaderFgColor: `#006FC5`,
  primaryLinkColor: '#0082CE',
  searchFieldClearIcon: '#AAAAAA',
  pgaGold: `#9e7702`,
  pgaNavyWithOpacity: 'rgba(0, 35, 75, 0.5)',

  colors: {
    primary: '#AB9157',
    secondary: '#00234B',
    warning: '#CE8113',
    danger: '#9D2332',
    dangerLight: '#FFF2F2',
    success: '#577632',
    info: '#0082CE',
    lightGray: '#F3F3F3',
    darkGray: '#CCCCCC',
    white: '#FFFFFF',
    black: '#000000',
    blueGray: '#9bacbf',
    gray: '#afafb2',
    lightBlueGray: '#9CC6DE'
  },

  fonts: {
    heading: "'Montserrat', sans-serif",
    body: '"Playfair Display", serif',
    weights: {
      normal: 400,
      bold: 700
    }
  },

  footerSectionHeader: 'white',
  footerSectionContent: 'gray',
  footerCopyright: '#999999',
  footerBackground: ' #00234b',
  primaryShadow: 'rgba(0, 35, 75, 0.15)'
}
