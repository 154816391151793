import React, { Fragment } from 'react'
import { withRouter, Prompt } from 'react-router-dom'
import { withAuthProvider } from '../../../lib/auth'
import { withState } from 'recompose'
import { pathOr, compose } from 'ramda'
import ModalView from './ModalView'
import { isPrivateRoute, isAuthRedirect } from './utils'

const withModal = withState('modalState', 'setModal', { open: false, returnLink: '/' })

export const isPromptActive = ({ setModal, isLoggedIn }) => (nextLocation) => {
  const isPrivate = isPrivateRoute(nextLocation)
  const authRedirect = isAuthRedirect(nextLocation)

  if (authRedirect) {
    setModal({
      open: true,
      returnLink: pathOr(nextLocation.pathname, ['state', 'from'], nextLocation)
    })
  }

  if (!isLoggedIn && isPrivate) {
    setModal({
      open: true,
      returnLink: pathOr(nextLocation.pathname, ['state', 'from'], nextLocation)
    })
    return false
  }
  return true
}

export const PromptModalView = ({ modalState, setModal, isLoggedIn }) => {
  return (
    <Fragment>
      <Prompt when message={isPromptActive({ setModal, isLoggedIn })} />
      <ModalView
        open={modalState.open}
        onConfirm={setModal}
        onCancel={setModal}
        returnLink={modalState.returnLink}
      />
    </Fragment>
  )
}

export const PromptModal = (props) => <PromptModalView {...props} />

export default compose(
  withAuthProvider,
  withRouter,
  withModal
)(PromptModal)
