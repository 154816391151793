import React from 'react'
import styled from 'styled-components'
import { Title, WarningIcon, Theme } from '@pga/pga-component-library'
import { is } from 'ramda'

export const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  box-shadow: 0 5px 10px 0 ${Theme.colors.primaryShadow};
  max-width: 600px;
  padding: 30px;

  & > * {
    margin-bottom: 25px;
  }
`

export const DetailsBox = styled.div`
  color: ${Theme.colors.primary};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 25px;
  text-align: center;
  text-shadow: 0 2px 4px 0 ${Theme.colors.darkGreyOpacity};
`

const MessageIcons = {
  warning: () => <WarningIcon />
}

export default ({ subject, details, type }) => <MessageBox>
  {
    MessageIcons[type]()
  }
  <Title className='text-center' >{subject}</Title>
  <DetailsBox>
    {
      is(Function, details)
        ? details()
        : <p>{details}</p>
    }
  </DetailsBox>
</MessageBox>
