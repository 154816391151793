import React from 'react'
import styled from 'styled-components'
import { Link as RRLink } from 'react-router-dom'
import { Container as RSContainer } from 'reactstrap'
import { Button, Theme } from '@pga/pga-component-library'
import ActionMessage from '../../components/ActionMessage'
import { RouterLink, Link, Mailto } from '../../components/Link'

const JOB_BOARD_HELP_EMAIL = 'jobboardhelp@pgahq.com'

const EmbedLink = styled(Link)`
  font-size: 16px;
`

export const EMPLOYER_LINK = 'https://account.pga.org/signup'

export const ApplyLink = ({ children }) => (
  <EmbedLink href={EMPLOYER_LINK} rel='noopener noreferrer' target='_blank'>
    {children}
  </EmbedLink>
)

export const MessageDetails = ({ page, isLoggedIn }) => {
  if (!isLoggedIn) {
    return (
      <div>
        <p className='mb-5'>
          Please sign in or <ApplyLink>apply for an employer account</ApplyLink>.<br />
        </p>
        <RRLink to={{ pathname: '/login', state: { page } }}><Button>Login</Button></RRLink>
        <div className='p-2'>
          <Link href={EMPLOYER_LINK}>Apply for Employer Account</Link>
        </div>
      </div>
    )
  }
  return (
    <div>
      <p className='mb-5'>
        Please <ApplyLink>apply for an employer account</ApplyLink>.<br />
        If you have more than one account, please make sure you are<br />
        logging in using your member or employer login.
      </p>
      <ApplyLink>
        <Button>apply for employer account</Button>
      </ApplyLink>
      <div className='p-2'>
        <RouterLink to='/'>Cancel and Exit</RouterLink>
      </div>
    </div>
  )
}

export const messageConfig = (page, isLoggedIn) => ({
  type: 'warning',
  subject: page ? 'This page requires a login.' : 'Sorry, you do not have permission to view this page.',
  details: () => <MessageDetails {...{ page, isLoggedIn }} />
})

const Container = styled(RSContainer)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
`

export const ReportAProblem = styled.div.attrs({
  className: 'text-center'
})`
  padding: 25px;
  color: ${Theme.colors.primary};
  font-family: ${Theme.fonts.Montserrat};
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 2px 4px 0 ${Theme.colors.darkGreyOpacity};
`

export default ({ page, isLoggedIn }) => (
  <Container className='align-middle h-100'>
    <ActionMessage {...messageConfig(page, isLoggedIn)} />
    <ReportAProblem>
      <p>
        To report a problem please contact our support department by sending an email to:<br />
        <Mailto address={JOB_BOARD_HELP_EMAIL} />
      </p>
    </ReportAProblem>
  </Container>
)
