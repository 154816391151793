import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

export const EMAIL_MIGRATION_QUERY = gql`
  query EMAIL_MIGRATION_QUERY {
    meEmailMigrationData {
      id
      emailAddress
      tempPassword
      accessDate
      doNotShowAgain
    }
  }
`
export const EMAIL_MIGRATION_MUTATION = gql`
  mutation updateEmailMigration($id: ID!, $input: UpdateEmailMigrationInput) {
    updateEmailMigration(id: $id, input: $input) {
      id
      emailAddress
      tempPassword
      accessDate
      doNotShowAgain
    }
  }
`

export const withSetEmailMigrationMutation = graphql(EMAIL_MIGRATION_MUTATION, {
  name: 'emailMigrationMutation'
})

export const withEmailMigrationData = graphql(EMAIL_MIGRATION_QUERY, {
  name: 'emailMigrationData'
})
