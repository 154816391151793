import N from 'numeral'
import moment from 'moment'
import { unlessNull } from '../'

export const currencyFormatter = (format = '$0,0.00') => unlessNull(val => N(val).format(format))

export const formatCurrencyEx = val => {
  const v = `${val}`
  if (!v) return ''
  else if (v.slice(-1) === '.') return N(v).format('$0,0') + '.'
  else if (v.slice(-2, -1) === '.') return N(v).format('$0,0[.]0')
  else return v !== '$' ? N(v).format('$0,0[.]00') : ''
}

const getPhoneParts = phoneNumber => `${phoneNumber}`.replace(/\D/g, '').match(/^(\d{3})(\d{3})(\d{4})$/)

export const formatPhone = phoneNumberString => {
  const phoneParts = getPhoneParts(phoneNumberString)
  return phoneParts ? `(${phoneParts[1]}) ${phoneParts[2]}-${phoneParts[3]}` : null
}

export const formatPhoneHref = phoneNumberString => {
  const phoneParts = getPhoneParts(phoneNumberString)
  return phoneParts ? `${phoneParts[1]}-${phoneParts[2]}-${phoneParts[3]}` : null
}

export const formatUnixDate = date => moment.unix(date).format('MM/DD/YYYY')
