import * as R from 'ramda'
import { privateRoutes } from '../Routes'

export const checkIfPrivate = item => R.filter(R.propEq('path', item))(privateRoutes) // dependency on `privateRoutes` list

export const isPrivateRoute = R.compose(
  R.head,
  checkIfPrivate,
  R.prop(['pathname'])
)

export const isAuthRedirect = R.path(['state', 'authRedirect'])
