import ApolloClient, { InMemoryCache } from 'apollo-boost'
import { getToken, getImpersonationHeader } from '../../lib/auth'
import { getAuthHeader, checkIfTokenExpired } from './helpers'

const cache = new InMemoryCache({})

const apolloClient = new ApolloClient({
  cache,
  uri: `${process.env.REACT_APP_V3_API_HOST}`,
  request: async operation => {
    // NOTE: tmp fix for the `jwt expired` errors we are seeing throughout jobs
    // In the future, Auth0 will handle this for us
    if (process.env.REACT_APP_AUTH_IMPL !== 'auth0') {
      await checkIfTokenExpired()
    }
    const token = await getToken()
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...getAuthHeader(token),
        ...getImpersonationHeader()
      }
    }))
  }
})

export default apolloClient
