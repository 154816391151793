import {
  propSatisfies,
  compose,
  equals,
  toLower,
  take,
  drop,
  complement,
  isNil,
  unless,
  sortWith,
  descend,
  ascend,
  prop
} from 'ramda'

export const isToLowerCasePropEqual = (value, prop) => obj =>
  propSatisfies(compose(equals(value), toLower), prop, obj)

export const notNil = complement(isNil)

export const unlessNull = unless(isNil)

export const takeSkip = (t, s) => compose(take(t), drop(s))

const handlePropType = (prp) => {
  return typeof prp === 'string'
    ? prp.split(' ').join('').toLowerCase()
    : prp
}

export const sortDescending = (item, collection) => (
  sortWith([
    descend(el => {
      const prp = prop(item, el)
      return handlePropType(prp)
    })
  ])
)(collection)

export const sortAscending = (item, collection) => (
  sortWith([
    ascend(el => {
      const prp = prop(item, el)
      return handlePropType(prp)
    })
  ])
)(collection)

export const greenhouseFacilities = facilities => facilities.filter(facility => facility.greenhouseId)

export const hasGHFacilities = facilities => !!greenhouseFacilities(facilities).length
