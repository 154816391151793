import gql from 'graphql-tag'

export const JOB_PREFERENCES_QUERY = gql`
  query JobPreferenceQuery {
    jobPreferenceFields {
      jobSearchStatus {
        label
        value
      }
      locations {
        label
        value
      }
    }
    jobCategories {
      label
      value
      subCategories {
        label
        value
        type
      }
    }

    me {
      id
      canSeePgaProJob
      jobPreference {
        id
        jobSearchStatus
        jobCategories
        minimumCompensation
        jobLocations
        openToSeasonal
        openToInternational
        optedInToJobSeekerEmails
      }
    }
  }
`
