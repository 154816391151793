import React, { useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { compose, path, pathOr } from 'ramda'
import { withAuthProvider } from '../../../lib/auth'
import { LoadingComponent } from '@pga/pga-component-library'
import { buildAlgoliaQuery, extractQueryParams } from '@pga/algolia-utils'

import { initIndex } from '../../../lib/algolia'
import { JOB_PREFERENCES_QUERY } from '../../../queries/member'

export const RecommendedJobsContext = React.createContext()
export const useRecJobsContext = () => React.useContext(RecommendedJobsContext)

const jobsIndex = initIndex(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
  process.env.REACT_APP_ALGOLIA_INDEX_NAME
)

export const fetchJobsData = async ({ setState, setLoading, client, isLoggedIn }) => {
  setLoading(true)
  const { data: preferencesData } = isLoggedIn ? await client.query({ query: JOB_PREFERENCES_QUERY }) : { data: {} }

  const jobCategories = path(['jobCategories'], preferencesData)
  const jobPreference = pathOr({}, ['me', 'jobPreference'], preferencesData)

  const algoliaQuery = buildAlgoliaQuery({ queryParams: extractQueryParams(jobPreference, jobCategories), hitsPerPage: 150 })

  const { hits: recommendedJobs } = algoliaQuery ? await jobsIndex.search(algoliaQuery) : []

  const { jobSearchStatus } = jobPreference
  setState({
    data: jobSearchStatus ? {
      recommendedJobs,
      jobSearchStatus,
      recommendedJobsIds: (recommendedJobs || []).reduce(
        (ids, { objectID }) => ({ ...ids, [objectID]: true }),
        {}
      )
    } : null
  })

  setLoading(false)
}

export const RecommendedJobsProvider = ({ children, client, isLoggedIn }) => {
  const [state, setState] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchJobsData({ setState, setLoading, client, isLoggedIn })
  }, [])

  return (
    <RecommendedJobsContext.Provider value={{ ...state, loading }}>
      {children}
    </RecommendedJobsContext.Provider>
  )
}

export const AuthReadyWrapper = (props) => (
  props.authReady ? <RecommendedJobsProvider {...props} /> : <LoadingComponent />
)

export default compose(
  withApollo,
  withAuthProvider
)(AuthReadyWrapper)
