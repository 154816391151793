import { getToken, setToken } from '../../lib/auth'
import jwtDecode from 'jwt-decode'

export const getAuthHeader = token => token && { Authorization: `Bearer ${token}` }

export const checkIfTokenExpired = async () => {
  try {
    const token = await getToken()

    const { exp } = jwtDecode(token)

    if (exp && (Date.now().valueOf() / 1000) > exp) {
      // token expired, clear it
      setToken('')
    }
  } catch (err) {
    // noop
  }
}
