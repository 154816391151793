import { gql } from 'apollo-boost'
import { graphql } from 'react-apollo'

export const getAuthAndMeQuery = gql`
  query {
    auth @client {
      isLoggedIn,
      me {
        firstName
        lastName
      }
    }
  }
`

export const GET_ME = gql`
  {
    me {
      firstName
      lastName
    }
  }
`

export const getAuth = gql`
  query {
    auth @client {
      isLoggedIn
    }
  }
`

export const jobsBySalesforceContactId = gql`
  query getMyJobs($input: GetMyJobsInput!) {
    getMyJobs(input: $input) {
      count,
      jobs {
        id
        offices {
          city
          state
          name
        }
        hiringManager {
          id
          lastName
          firstName
        }
        applicantGroup
        jobCategory
        jobTitle
        status
        employmentType
        yearsExperience
        submissionDeadline
        baseCompensation
        baseCompensationMax
        paySchedule
        additionalIncome
        totalCompensation
        contact {
          name
          title
          email
        }
        education
        applyVia
        website
        overview
        experienceRequired
        responsibilities
        benefits
        termOfServiceAgreement
        currentStep
        createdAt
        openings {
          id
          openedAt
          status
        }
        applicants {
          id
          firstName
          lastName
          email
          resumeLink
          coverLetterLink
        }
        caseStatus
      }
    }
  }
`

export const getAuthAndMe = query =>
  graphql(query, {
    props: ({ data }) => ({
      data
    })
  })

export const getLoggedIn = query =>
  graphql(query, {
    props: ({ data }) => ({
      isLoggedIn: data.auth ? data.auth.isLoggedIn : false
    })
  })

export const getJobs = query =>
  graphql(query, {
    props: ({ data }) => ({
      data,
      loading: data.loading,
      error: data.error
    })
  })

export const JobPermissionsQuery = gql`
  query jobPermissions($contactId: ID!) {
    jobPermissions(contactId: $contactId) {
      id
      name
    }
  }
`

export const getMeUser = gql`
  query User {
    me {
      id
      primaryEmail
      type
      firstName
      lastName
      photo
      class
      type
      displayName
      role
      universalId
      salesforceId
      canSeePgaProJob
    }
  }
`
