import { reduce, map, filter, find, propEq } from 'ramda'

const AUTH = 'auth'
const UNAUTH = 'noAuth'
export const subNavLinks = [
  {
    id: 1,
    tags: 'common',
    links: [
      {
        id: 1,
        label: 'Find a Job',
        to: '/',
        exact: true,
        isActive: (match, location) => {
          if (!location) return false
          return location.pathname === '/'
        }
      },
      {
        id: 2,
        label: 'Post a Job',
        to: '/post',
        exact: false
      },
      {
        id: 3,
        label: 'Manage Jobs',
        to: '/manage-jobs',
        exact: false
      }
    ]
  }, {
    id: 2,
    tags: 'auth',
    links: [
      {
        id: 1,
        label: 'Career Services',
        to: 'https://resources.pga.org/career-advancement/career-services/',
        exact: false,
        external: true
      },
      {
        id: 2,
        label: 'Job Preferences',
        to: 'https://account.pga.org/job-preferences/',
        exact: false,
        external: true
      }
    ]
  }, {
    id: 3,
    tags: 'noAuth',
    links: [
      {
        id: 1,
        label: 'Career Services',
        to: 'https://beta.pga.org/career-services/',
        exact: false,
        external: true
      }
    ]
  }
]

const authStatusLinks = {
  auth: [{
    categoryId: 1,
    linksId: [1, 2, 3]
  }, {
    categoryId: 2,
    linksId: [1, 2]
  }],
  noAuth: [{
    categoryId: 1,
    linksId: [1, 2, 3]
  }, {
    categoryId: 3,
    linksId: [1]
  }]
}

export default isLoggedIn => {
  const authLinks = authStatusLinks[isLoggedIn ? AUTH : UNAUTH]
  return reduce(
    (acc, { categoryId, linksId }) => {
      const { links } = filter(({ id }) => id === categoryId, subNavLinks)[0]
      return acc.concat(map(id => find(propEq('id', id), links), linksId))
    },
    [],
    authLinks
  )
}
