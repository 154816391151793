import React, { Fragment } from 'react'
import styled, { withTheme } from 'styled-components'
import { SecondaryNav, IframeRolex } from '@pga/pga-component-library'

import { withAuthProvider } from '../../lib/auth'
import getLinksFor from './DashboardOptions'

const NavFrame = styled.div`
  padding-bottom: 30px;
  z-index: 5;

  @media (max-width: 991px) {
    padding: 0;
    margin: 0;
  }
`

export const SubNavigation = ({ authReady, isLoggedIn }) => (
  <NavFrame>
    {
      authReady
        ? (
          <Fragment>
            <SecondaryNav options={getLinksFor(isLoggedIn)} title='Jobs' menuTitle='Jobs Menu' />
            <IframeRolex mobile />
          </Fragment>
        )
        : null
    }
  </NavFrame>
)

export default withAuthProvider(withTheme(SubNavigation))
