import React from 'react'
import { compose } from 'ramda'
import { withAuthProvider } from '../../lib/auth'
import { NavLink } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import MediaQuery from 'react-responsive'
import logo from '../../assets/images/logo.svg'
import { LoadingComponent, Header, Nav, IconsBox, MobileNavigation, Theme, IframeRolex } from '@pga/pga-component-library'
import { loggedInOptions, loggedOutOptions, loggedInMobileOptions, loggedOutMobileOptions, dropdownLinks } from './options'
import AuthHeader from '../AuthHeader'
import { withEmailMigrationData, withSetEmailMigrationMutation } from './query'

const NavigationBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const SideNavLink = styled(NavLink)`
  font-family: ${Theme.fonts.Montserrat};
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  position: relative;

  &.active::before {
    content: '';
    position: absolute;
    vertical-align: middle;
    width: 100%;
    color: ${Theme.colors.gold};
    bottom: -10px;
    border-bottom: 4px solid;
  }

  :hover, &.active {
    text-decoration: none;
    color: #fff;
  }
`

const LinkStyled = styled(NavLink)`
  color: #fff;
  text-decoration: none;
  font-family: ${props => props.theme.fonts.heading};
  font-weight: 700;
  font-size: 16px;

  width: 100%;
  flex-grow: 45;
  position: relative;

  &.active::before {
    content: '';
    position: absolute;
    vertical-align: middle;
    width: 100%;
    color: ${props => props.theme.pgaGold};
    bottom: -31px;
    border-bottom: 4px solid;
  }

  :hover,
  &.active {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
  }
`

export const JobsHeader = ({ theme, me, isLoggedIn, authReady, emailMigrationData, emailMigrationMutation }) => {
  const options = isLoggedIn ? loggedInOptions : loggedOutOptions
  const mobileOptions = isLoggedIn ? options.concat(loggedInMobileOptions) : options.concat(loggedOutMobileOptions)

  const { loading, meEmailMigrationData: migrationData } = emailMigrationData || {}
  const meEmailMigrationData = migrationData || {}
  const { id, doNotShowAgain } = meEmailMigrationData

  const onToggleDoNotShowAgain = () => {
    emailMigrationMutation({ variables: { id, input: { doNotShowAgain: !doNotShowAgain } } })
  }

  const logoLink = isLoggedIn ? 'https://account.pga.org' : 'https://beta.pga.org'
  if (!authReady || loading) {
    return <LoadingComponent />
  }
  return (
    <Header {...{ logo, bgColor: theme.colors.secondary, logoLink }}>
      <MediaQuery minWidth={992}>
        <NavigationBox>
          <Nav options={options} comp={LinkStyled} />
          <div className='d-flex align-items-center'>
            <IframeRolex />
            <IconsBox {...{ isLoggedIn, meEmailMigrationData, onToggleDoNotShowAgain, me }} />
          </div>
          <AuthHeader {...{ isLoggedIn, me, authReady, options: dropdownLinks, meEmailMigrationData, onToggleDoNotShowAgain }} />
        </NavigationBox>
      </MediaQuery>
      <MediaQuery maxWidth={991}>
        <MobileNavigation {...{ linkComponent: SideNavLink, options: mobileOptions, me, comp: SideNavLink, b: -7, l: 11, isLoggedIn, meEmailMigrationData, onToggleDoNotShowAgain }} vertical />
      </MediaQuery>
    </Header>
  )
}

export default compose(
  withEmailMigrationData,
  withSetEmailMigrationMutation,
  withTheme,
  withAuthProvider
)(JobsHeader)
