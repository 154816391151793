import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Theme, Button, LinkIcon } from '@pga/pga-component-library'
import styled from 'styled-components'
import { Modal as RSModal, ModalHeader as RSModalHeader, Row, Col } from 'reactstrap'
import { EMPLOYER_LINK } from '../../common/elements/RegisterAsEmployer'

export const ModalHeader = styled(RSModalHeader)`
  border-bottom: none;
  position: absolute;
  right: 10px;
  z-index: 10;
`
export const Modal = styled(RSModal)`
  .modal-content {
    border-radius: 0;
    border: none;
  }
`

export const Title = styled.h4`
  color: ${Theme.colors.primary};
  font-family: ${Theme.fonts.PlayfairDisplay};
  font-size: ${props => props.fontSize || '26px'};
  font-weight: ${props => props.fontWeight || '600'};
  letter-spacing: -0.2px;
  line-height: 35px;
  margin-bottom: 1rem;
`

const Paragraph = styled.p`
  color: ${Theme.colors.primary};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 1rem;
`

export const LoginButton = styled(Button)`
  font-family: ${Theme.fonts.Montserrat};
  font-size: 14px;
  line-height: 18px;
  display: block;
  width: 100%;
`
const PaddingWrapper = styled.div`
  padding: 3rem;
  height: 100%;
  background-color: #E9EBEE;
  border-left: 1px solid #B4BDCC;
`
export const AnchorLink = styled.a`
  color: ${Theme.colors.lightBlue};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 14px;
`

export const EmployerLink = ({ children }) => (
  <AnchorLink href={EMPLOYER_LINK} rel='noopener noreferrer' target='_blank'>
    {children}
  </AnchorLink>
)

export default ({ returnLink, open, onCancel }) => {
  return (
    <Fragment>
      <Modal isOpen={open} toggle={() => onCancel(false)} size='lg' centered>
        <ModalHeader toggle={() => onCancel(false)} />
        <Row>
          <Col lg='7'>
            <div className='p-5'>
              <Title>Login to post or manage jobs.</Title>
              <Paragraph>
                If you are a PGA Member or an employer with an existing account, please login to post or manage jobs.
              </Paragraph>
              <Link to={{ pathname: '/login', state: { page: returnLink } }}><LoginButton onClick={() => onCancel(false)}>Login</LoginButton></Link>
            </div>
          </Col>
          <Col lg='5'>
            <PaddingWrapper>
              <Title fontSize='18px' fontWeight='500'>Need an account?</Title>
              <Paragraph>
                If you are a new employer who would like to post a job, please create an account to continue.
              </Paragraph>
              <EmployerLink>
                Create employer account <LinkIcon />
              </EmployerLink>
            </PaddingWrapper>
          </Col>
        </Row>
      </Modal>
    </Fragment>
  )
}
